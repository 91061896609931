import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo"
import Layout from "../components/layout"
import CustomerJourney from "../components/customerJourney"
import PortfolioCarousel from "../components/portfolio-carousel"

import "./index.scss";

const IndexPage = (queryData) => {
  return (
    <Layout>
      <SEO title="Travelsoftware voor elke customer journey fase" description={queryData.data.gcms.metaDescription.value} urlParams="" page="homepage" />
      <img className="print-image" src='https://media.travelspirit.nl/original/sm/TravelSpirit_Logo-FC.png' alt="logo" loading="lazy" />
      <CustomerJourney />
      <div className="portfolio-container">
        <PortfolioCarousel />
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
query IndexPageQuery {
  gcms {
    metaDescription(where: {indicator: "home-meta-description"}) {
      value
    }
  }
}`;
